import { createTrackingUtils } from "utils/experiments";
import { useSelector } from "react-redux";
import { getUserInfo } from "store/user/selectors";
import { useEffect } from "react";

export const trackGeniusAirlinesIndexCampaign = createTrackingUtils({
  name: "flights_web_cat_genius_airlines_campaign_index",
  defaultStage: 1,
  stages: {
    all_genius: 1,
    genius_1: 2,
    genius_2: 3,
    genius_3: 4
  },
  goals: {
    tooltip_hover: 1,
    clicked_on_airline: 2
  },
  goalsWithValue: []
});

export function useTrackGeniusAirlinesIndexStages() {
  const user = useSelector(getUserInfo);

  useEffect(() => {
    if (user && user.geniusLevel >= 1) {
      trackGeniusAirlinesIndexCampaign.stages.all_genius();

      switch (user.geniusLevel) {
        case 1:
          trackGeniusAirlinesIndexCampaign.stages.genius_1();
          break;
        case 2:
          trackGeniusAirlinesIndexCampaign.stages.genius_2();
          break;
        case 3:
          trackGeniusAirlinesIndexCampaign.stages.genius_3();
          break;
        default:
          break;
      }
    }
  }, [user]);
}
