import React from "react";
import { Actionable, Banner, Icon, Image, Stack, Text } from "@bookingcom/bui-react";
import { t } from "@bookingcom/lingojs-core";
import { useI18n } from "@bookingcom/lingojs-react";
import { useFormatDateTime } from "@bookingcom/flights-core";
import styles from "components/elements/WeekendBreaks/WeekendBreaksItemInnerDesktop.module.css";
import { PlaneTripTimeIcon } from "@bookingcom/bui-assets-react/streamline";
import { customGoal, goalWithValue } from "utils/etV2";
import { getDaysNumber } from "components/elements/WeekendBreaks/utils";
import { DayOfWeek } from "@flights/types";

type Props = {
  linkUrlSR: string;
  arrivalCityImageUrl: string;
  durationCopy: string;
  arrivalCityNameTranslated: string;
  departureTime: string;
  returnDepartureTime: string;
  price: string;
  departureDayOfWeek: DayOfWeek;
  tripDurationDays: number;
};

const WeekendBreaksItemInnerDesktop = ({
  linkUrlSR,
  arrivalCityImageUrl,
  durationCopy,
  arrivalCityNameTranslated,
  departureTime,
  returnDepartureTime,
  price,
  departureDayOfWeek,
  tripDurationDays
}: Props) => {
  const i18n = useI18n();
  const { formats } = useFormatDateTime(i18n);

  const handleClick = () => {
    customGoal("flights_web_cat_weekend_breaks_www", 1);
    goalWithValue("flights_web_cat_weekend_breaks_www_days", getDaysNumber(departureDayOfWeek, tripDurationDays));
  };

  return (
    <Actionable href={linkUrlSR} attributes={{ style: { display: "block" } }} onClick={handleClick}>
      <Banner dismissible={false} className={styles.banner}>
        <Stack gap={0}>
          <Image src={arrivalCityImageUrl} className={styles.top} fallback="icon" />
          <Stack.Item className={styles.bottom}>
            <Stack direction="column" gap={0}>
              <Text variant="small_1" color="neutral">
                <Icon svg={PlaneTripTimeIcon} size="smaller" className={styles.icon} /> {durationCopy}
              </Text>
              <Text variant="strong_1" color="neutral" className={styles.city}>
                {arrivalCityNameTranslated}
              </Text>
              <Text variant="body_2" color="neutral">
                {formats.flightDateWeekday(departureTime)} - {formats.flightDateWeekday(returnDepartureTime)}
              </Text>
              <Stack direction="row" wrap="wrap" alignItems="baseline" className={styles.priceWrapper}>
                <Text variant="headline_2" color="neutral" className={styles.price}>
                  {price}
                </Text>
                <Text variant="small_1" color="neutral_alt">
                  {i18n.trans(t("flights_cheap_weekend_carousel_price"))}
                </Text>
              </Stack>
            </Stack>
          </Stack.Item>
        </Stack>
      </Banner>
    </Actionable>
  );
};

export default WeekendBreaksItemInnerDesktop;
