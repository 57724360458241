import { useMemo } from "react";
import { DayOfWeek, UICabinClass } from "@flights/types";
import useCreateUrl from "hooks/useCreateUrl";

const useGenerateWeekendBreakCardUrl = (
  arrivalCityIata: string,
  departureCityIata: string,
  salesCountry: string,
  departureCountry: string,
  departureDate: string,
  returnDate: string,
  tripType: string,
  numberOfAdults: string,
  cabinClass: UICabinClass
) => {
  const { enhanceURLSearchParams } = useCreateUrl();

  return useMemo(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const _tripType = tripType === "ROUND_TRIP" ? "ROUNDTRIP" : "ONEWAY";

    urlParams.set("to", arrivalCityIata);
    urlParams.set("from", `${departureCityIata}`);
    urlParams.set("salesCountry", salesCountry || "");
    urlParams.set("fromCountry", departureCountry || "");
    urlParams.set("depart", departureDate || "");
    urlParams.set("return", returnDate || "");
    urlParams.set("type", tripType ? _tripType : "");
    urlParams.set("adults", numberOfAdults || "");
    urlParams.set("cabinClass", cabinClass || "");
    urlParams.set("ca_source", "weekend_breaks");

    enhanceURLSearchParams(urlParams);

    return `/flights/${departureCityIata}.CITY-${arrivalCityIata}.CITY/?${urlParams.toString()}`;
  }, [
    tripType,
    arrivalCityIata,
    departureCityIata,
    salesCountry,
    departureCountry,
    departureDate,
    returnDate,
    numberOfAdults,
    cabinClass,
    enhanceURLSearchParams
  ]);
};

function getDaysNumber(day: DayOfWeek, duration: number) {
  if (day === "THURSDAY" && duration === 3) return 1;
  if (day === "FRIDAY" && duration === 2) return 2;
  if (day === "FRIDAY" && duration === 3) return 3;
  if (day === "SATURDAY" && duration === 1) return 4;
  return 0;
}

export { getDaysNumber, useGenerateWeekendBreakCardUrl };
