import { Stack, Text, Title, useTheme } from "@bookingcom/bui-react";
import { SearchboxController, SearchBoxVerticalDefault } from "@bookingcom/flights-searchbox";
import { t } from "@bookingcom/lingojs-core";
import { I18nChildContext, useI18n } from "@bookingcom/lingojs-react";
import FrequentlyAskedQuestions from "components/elements/FrequentlyAskedQuestions";
import UspBlock from "components/elements/UspBlock";
import React, { Profiler, useContext, useEffect, useRef } from "react";
import { Helmet } from "react-helmet-async";
import { trackMetaLandingsCustomGoalsV2 } from "utils/experiments/track-meta-landings-v2";
import { Search } from "app/routes";
import AppShell from "../components/elements/AppShell";
import useClientMetrics from "hooks/useClientMetrics";
import useGlobalContext from "hooks/useGlobalContext";
import useLocaleContext from "hooks/useLocaleContext";
import useSearchbox from "hooks/useSearchbox";
import useTrackPageLoad from "hooks/useTrackPageLoad";
import { trackExperiment, trackExperimentStage, trackGoal } from "utils/et";
import NoJS from "./NoJS";
import StaticContext from "app/StaticContext/StaticContext";
import { isOfMetaOrigin, isOfPPCOrigin } from "utils/marketing-url-params";
import Frame from "components/elements/Frame";
import { HomeSearchHeader } from "components/elements/HomeSearchHeader";
import Container from "../components/elements/Container";
import {
  RemoteAppleCardIndex,
  RemoteGeniusLaunchSheet,
  RemoteGeniusLoyalty,
  RemoteSEOInterlinking,
  RemoteRewardsLandSheet
} from "components/remote";
import { IndexCampaignBanner } from "components/elements/IndexCampaignBanner";
import CheapFlights from "components/elements/CheapFlights";
import ThemeBasedDestinations from "components/elements/ThemeBasedDestinations/ThemeBasedDestinations";
import useLandingPageEvents from "hooks/c360/useLandingPageEvents";
import HomeRecentSearchList from "components/elements/HomeRecentSearch/HomeRecentSearchList";
import NoSSR from "app/NoSSR";
import { useTrackFlyAnywhere } from "hooks/useTrackFlyAnywhere";
import CrowdstrikeOutage from "components/elements/CrowdstrikeOutage";
import { useTrackPpcWithoutSearchHistory } from "utils/experiments/customer-aquisition/flights_web_cat_ppc_without_search_history_aa";
import { FlightsInterlinking } from "components/elements/FlightsInterlinking";
import { stage, trackWithDefaultStage } from "utils/etV2";
import flights_web_cat_theme_based_destinations_mdot from "utils/experiments/customer-aquisition/flights_web_cat_theme_based_destinations_mdot";
import useIsInViewport from "hooks/useIsInViewport";
import EsimBanner from "ancillaries/components/Esim/EsimBanner";
import { useSelector } from "react-redux";
import { getEsimEligibility } from "store/esim/selectors";
import flights_ace_web_esim_banner from "utils/experiments/ace/flights_ace_web_esim_banner";
import { useEsimIndex } from "ancillaries/hooks/Esim/useEsimFetch";
import BlackFridayBanner from "components/elements/BlackFridayBanner";
import { BLACK_FRIDAY_EXCLUDED_COUNTRIES } from "../constants";
import Wrapper from "components/atoms/Wrapper";
import { getUserInfo } from "store/user/selectors";
import { getFlightsInterlinking } from "store/flightsInterlinking/selector";
import WeekendBreaks from "components/elements/WeekendBreaks";

const Home = () => {
  const { features, ipCountry = "" } = useGlobalContext();
  const staticContext = useContext(StaticContext);
  const theme = useTheme();
  const i18n = useI18n();
  const trackContextEventV2 = useTrackPageLoad("index");
  const {
    userAgent: { isWebview }
  } = useGlobalContext();
  const searchBoxProps = useSearchbox();
  const { isRTL } = useLocaleContext();
  const { trackRenderTime } = useClientMetrics();
  const trackLandingPageEvents = useLandingPageEvents();
  const { trackFlyAnywhereMdot } = useTrackFlyAnywhere();
  /** start - flights_ace_web_esim_banner */
  const isEsimEligibilty = !!useSelector(getEsimEligibility);
  const showEsimBanner = isEsimEligibilty && !!flights_ace_web_esim_banner.trackWithDefaultStage();
  const fetchEsim = useEsimIndex();
  const esimBannerRef = useRef<HTMLDivElement>(null);
  const eSimInViewport = useIsInViewport(esimBannerRef.current);
  /** end - flights_ace_web_esim_banner */

  const userInfo = useSelector(getUserInfo);

  if (process.env.BUILD_TARGET === "server" && staticContext.set) {
    const { getInitialState } = require("../server/screens/home");
    staticContext.set({ getInitialState });
  }

  /** start - flights_ace_web_esim_banner */
  useEffect(() => {
    void fetchEsim();
  }, [fetchEsim]);

  useEffect(() => {
    if (showEsimBanner && userInfo) {
      flights_ace_web_esim_banner.stages.with_booking();
    }
  }, [showEsimBanner, userInfo]);

  useEffect(() => {
    if (eSimInViewport && userInfo) {
      flights_ace_web_esim_banner.goals.viewed_esim_banner();
    }
  }, [eSimInViewport, userInfo]);
  /** end - flights_ace_web_esim_banner */

  useEffect(() => {
    Search.preload();
    trackContextEventV2();
    trackGoal("flights_home_page_view");
    trackLandingPageEvents("page_view", "homepage");

    // track meta users going to index page
    trackMetaLandingsCustomGoalsV2(2, "mdot");
    trackExperimentStage("flights_web_a11y_searchbox", 1); // stage 1 - Desktop + Mdot Index + Search results - a11y searchbox
    trackExperimentStage("flights_web_a11y_searchbox", 3); // stage 3 - Mdot - Index - a11y searchbox

    trackExperimentStage("flights_web_search_header_ui_update_mdot", 1);
    trackExperimentStage("flights_web_search_header_ui_update_mdot", isOfMetaOrigin() ? 3 : 2);

    flights_web_cat_theme_based_destinations_mdot.stages.main();

    stage("cm_post_trip_add_rewards_landsheet_to_flights_web", 3);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isBlackFridayEligible =
    !isOfPPCOrigin() && !isOfMetaOrigin() && !BLACK_FRIDAY_EXCLUDED_COUNTRIES.includes(ipCountry);

  useEffect(() => {
    if (isBlackFridayEligible) {
      stage("flights_web_cat_black_friday_hero", 3);
    }
  }, [isBlackFridayEligible]);

  const { canShowOnMdot } = useTrackPpcWithoutSearchHistory(true);

  return (
    <Profiler id="screen" onRender={trackRenderTime}>
      <PageHeader />
      <AppShell noPadding>
        <Frame alignItems="center" direction="column" justifyContent="center" grow={1}>
          {!!trackExperiment("flights_web_search_header_ui_update_mdot") ? (
            <HomeSearchHeader />
          ) : (
            <Frame
              attributes={{
                style: {
                  backgroundColor: isWebview
                    ? theme.colors.color_background_base
                    : theme.colors.color_background_disabled_alt,
                  width: "100%"
                }
              }}
              pr={4}
              pl={4}
            >
              <NoJS />
              <Frame mt={4} mb={4}>
                <Stack gap={2}>
                  <Text variant="headline_1" tagName="h1">
                    {i18n.trans(t("flights_index_header_compare_book_ease"))}
                  </Text>
                  {!isWebview && (
                    <span style={{ color: theme.colors.color_foreground_alt }}>
                      <Text variant="body_1">{i18n.trans(t("flights_index_header_discover_next_dream"))}</Text>
                    </span>
                  )}
                </Stack>
              </Frame>
              <Frame mb={6}>
                <SearchboxController
                  i18n={i18n}
                  {...searchBoxProps}
                  isRTL={isRTL}
                  trackFlyAnywhereMdot={() => trackFlyAnywhereMdot()}
                  onSearch={(searchParams) => {
                    trackLandingPageEvents("click", "searchbox");
                    searchBoxProps.onSearch(searchParams);
                  }}
                >
                  {() => <SearchBoxVerticalDefault />}
                </SearchboxController>
                <CrowdstrikeOutage />
              </Frame>
            </Frame>
          )}

          {isBlackFridayEligible && trackWithDefaultStage("flights_web_cat_black_friday_hero", 1) ? (
            <BlackFridayBanner />
          ) : undefined}

          {canShowOnMdot && (
            <Frame attributes={{ style: { width: "100%" } }} mb={6}>
              <UspBlock isPPCVariant={true} />
            </Frame>
          )}

          <NoSSR>
            <HomeRecentSearchList />
          </NoSSR>

          <WeekendBreaks />

          <IndexCampaignBanner />

          {!!flights_web_cat_theme_based_destinations_mdot.trackWithDefaultStage() ? (
            <ThemeBasedDestinations isMobile={true} />
          ) : (
            <CheapFlights isMobile={true} />
          )}

          <RemoteAppleCardIndex />

          {!!features.FLIGHTS_WEB_CAT_GENIUS_LOYALTY_RC ? <RemoteGeniusLoyalty /> : undefined}

          {showEsimBanner && (
            <Container style={{ margin: `${theme.units.spacing_8x} auto`, alignItems: "start" }}>
              <Title title={i18n.trans(t("flights_genius_section_header"))} variant="headline_2" titleTagName="h2" />
              <Wrapper elementRef={esimBannerRef} noBorder noPadding>
                <EsimBanner isPostBooking={true} />
              </Wrapper>
            </Container>
          )}

          {!canShowOnMdot && (
            <Frame attributes={{ style: { width: "100%" } }} mb={6}>
              <UspBlock hideBackground={!!trackExperiment("flights_web_search_header_ui_update_mdot")} />
            </Frame>
          )}

          {!!useSelector(getFlightsInterlinking) ? <FlightsInterlinking /> : <RemoteSEOInterlinking isMobile={true} />}

          <Frame attributes={{ style: { width: "100%" } }} grow={1} p={4}>
            <FrequentlyAskedQuestions />
          </Frame>
        </Frame>
      </AppShell>
      {!!features.FLIGHTS_WEB_CAT_GENIUS_LAUNCH_SHEET_RC ? <RemoteGeniusLaunchSheet /> : undefined}
      {trackWithDefaultStage("cm_post_trip_add_rewards_landsheet_to_flights_web", 1) ? (
        <RemoteRewardsLandSheet />
      ) : undefined}
    </Profiler>
  );
};

function PageHeader(props: { children?: React.ReactNode }) {
  const i18n = useI18n() as I18nChildContext;

  return (
    <Helmet>
      <title>
        {i18n.trans(
          t("mlx_flights_index_meta_title", {
            variables: { b_companyname: "Booking.com" }
          })
        )}
      </title>
      <meta name="description" content={i18n.trans(t("mlx_flights_index_meta_description"))} />
      <meta property="webview:header" content="regular" />
      <meta property="webview:title" content={""} />
      {props.children}
    </Helmet>
  );
}

export default Home;
export { PageHeader };
