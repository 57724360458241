import React from "react";
import { Actionable, Banner, Image, Stack, Text } from "@bookingcom/bui-react";
import { useI18n } from "@bookingcom/lingojs-react";
import { useFormatDateTime } from "@bookingcom/flights-core";
import { customGoal, goalWithValue } from "utils/etV2";
import styles from "./WeekendBreaksItemInner.module.css";
import { DayOfWeek } from "@flights/types";
import { getDaysNumber } from "./utils";

type Props = {
  linkUrlSR: string;
  arrivalCityImageUrl: string;
  arrivalCityNameTranslated: string;
  departureTime: string;
  returnDepartureTime: string;
  price: string;
  departureDayOfWeek: DayOfWeek;
  tripDurationDays: number;
};

const WeekendBreaksItemInnerMobile = ({
  linkUrlSR,
  arrivalCityImageUrl,
  arrivalCityNameTranslated,
  departureTime,
  returnDepartureTime,
  price,
  departureDayOfWeek,
  tripDurationDays
}: Props) => {
  const i18n = useI18n();
  const { formats } = useFormatDateTime(i18n);

  const handleClick = () => {
    customGoal("flights_web_cat_weekend_breaks_mdot", 1);
    goalWithValue("flights_web_cat_weekend_breaks_mdot_days", getDaysNumber(departureDayOfWeek, tripDurationDays));
  };

  return (
    <Actionable href={linkUrlSR} attributes={{ style: { display: "block" } }} onClick={handleClick}>
      <Banner dismissible={false} className={styles.banner}>
        <Stack direction="row" gap={0}>
          <Image src={arrivalCityImageUrl} className={styles.left} fallback="icon" />
          <Stack.Item className={styles.right}>
            <Stack direction="column" gap={0}>
              <Text variant="strong_2" color="neutral">
                {arrivalCityNameTranslated}
              </Text>
              <Text variant="small_1" color="neutral">
                {formats.flightDate(departureTime)} - {formats.flightDate(returnDepartureTime)}
              </Text>
              <Stack direction="row" wrap="wrap" alignItems="baseline" className={styles.priceWrapper}>
                <Text variant="strong_1" color="neutral">
                  {price}
                </Text>
              </Stack>
            </Stack>
          </Stack.Item>
        </Stack>
      </Banner>
    </Actionable>
  );
};

export default WeekendBreaksItemInnerMobile;
