import React, { useMemo } from "react";
import { useSelector } from "react-redux";

import { t } from "@bookingcom/lingojs-core";
import { Carousel, Stack, Text, Title, useViewport } from "@bookingcom/bui-react";
import { useI18n } from "@bookingcom/lingojs-react";
import type CarouselProps from "@bookingcom/bui-react/components/Carousel";

import Container from "../Container";
import { getWeekendBreaks } from "store/weekendBreaks/selectors";
import { PriceDisclaimerDesktop } from "components/elements/PriceDisclaimer/PriceDisclaimer.desktop";
import styles from "./WeekendBreaks.module.css";
import WeekendBreaksItem from "./WeekendBreaksItem";
import useUserAgent from "hooks/useUserAgent";
import { mcn } from "utils/mergeClassnames";
import { WEEKEND_BREAKS_DAYS } from "../../../constants";

type CarouselSize = React.ComponentProps<typeof CarouselProps>["size"];

const WeekendBreaks = () => {
  const i18n = useI18n();
  const { isSmall, isMedium } = useViewport();
  const { isMobile } = useUserAgent();

  const weekendBreaks = useSelector(getWeekendBreaks);

  const cityName = weekendBreaks?.[0]?.departureCityName ?? "";

  const carouselSize = useMemo((): CarouselSize => {
    let size: CarouselSize = "medium";
    if (isMedium) {
      size = "large";
    } else if (isSmall) {
      size = "largest";
    }

    return size;
  }, [isSmall, isMedium]);

  if (!weekendBreaks?.length) return null;

  return (
    <Container className={mcn(styles.container, isMobile && styles.mobileContainer)}>
      <Title
        title={i18n.trans(
          t("flights_cheap_weekend_carousel_title", {
            variables: { num_days: WEEKEND_BREAKS_DAYS },
            num_exception: WEEKEND_BREAKS_DAYS
          })
        )}
        subtitle={
          <Stack direction="row" justifyContent="start" alignItems="center" wrap="wrap">
            {i18n.trans(t("flights_cheap_weekend_carousel_subtitle", { variables: { city_name: cityName } }))}{" "}
            {!isMobile ? <PriceDisclaimerDesktop copyTag="flights_cheap_weekend_carousel_disclaimer" /> : null}
          </Stack>
        }
        variant="headline_2"
        titleTagName="h2"
      />
      <Carousel
        size={isMobile ? "largest" : carouselSize}
        nextButtonAriaLabel={i18n.trans(t("a11y_flights_route_carousel_next_button_aria_label"))}
        previousButtonAriaLabel={i18n.trans(t("a11y_flights_route_carousel_previous_button_aria_label"))}
        className={styles.carousel}
      >
        {weekendBreaks.map((item, idx) => (
          <WeekendBreaksItem key={idx} item={item} />
        ))}
      </Carousel>
      {isMobile ? (
        <Text variant="small_2" className={styles.disclaimer}>
          {i18n.trans(t("flights_cheap_weekend_carousel_disclaimer"))}
        </Text>
      ) : null}
    </Container>
  );
};

export default WeekendBreaks;
