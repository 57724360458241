import { Banner, Icon, Image, Popover, Stack, Text } from "@bookingcom/bui-react";
import React, { memo, useMemo } from "react";
import Frame from "../Frame/Frame";
import Container from "components/elements/Container";
import { InfoSignIcon } from "@bookingcom/bui-assets-react/streamline/index.js";
import {
  trackGeniusAirlinesIndexCampaign,
  useTrackGeniusAirlinesIndexStages
} from "utils/experiments/customer-aquisition/flights_web_cat_genius_airlines_campaign_index";
import styles from "./GeniusAirlinesBanner.module.css";
import { getIndexCampaigns } from "store/indexCampaigns/selectors";
import { useSelector } from "react-redux";

export const GeniusAirlinesBanner = memo(function _GeniusAirlinesBanner() {
  const geniusAirlineBannerData = useSelector(getIndexCampaigns).geniusAirlines;

  useTrackGeniusAirlinesIndexStages();

  const showBanner = !!geniusAirlineBannerData && trackGeniusAirlinesIndexCampaign.trackWithDefaultStage();

  const carriersList = useMemo(() => {
    if (!showBanner) {
      return null;
    }

    return (
      <Stack direction="row" justifyContent="space-between" attributes={{ role: "listbox" }}>
        {geniusAirlineBannerData.airlines.map(({ name, imageUrl }) => (
          <>
            {!!name && !!imageUrl && (
              // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
              <div
                style={{ flex: 1 }}
                key={name}
                role="listitem"
                onClick={trackGeniusAirlinesIndexCampaign.goals.clicked_on_airline}
              >
                <Stack justifyContent="space-between" alignItems="center">
                  <Image width="32px" src={imageUrl} alt="" />
                  <Text variant="small_2" align="center">
                    {name}
                  </Text>
                </Stack>
              </div>
            )}
          </>
        ))}
      </Stack>
    );
  }, [geniusAirlineBannerData, showBanner]);

  if (!showBanner || !carriersList) {
    return null;
  }

  return (
    <Container>
      <Frame mt={6} mb={8}>
        <Banner
          title={
            <Stack direction="row" alignItems="center" gap={1}>
              <Text variant="strong_1" attributes={{ "aria-describedby": "genius_airlines_tooltip" }}>
                {geniusAirlineBannerData.title}
              </Text>

              {geniusAirlineBannerData.tooltip && (
                <Popover
                  position="top-start"
                  forcePosition
                  triggerType="hover"
                  navigationMode="tab"
                  trapFocusMode="soft"
                  hideClose={true}
                  keepMounted={true}
                  onOpen={trackGeniusAirlinesIndexCampaign.goals.tooltip_hover}
                >
                  <Popover.Trigger>
                    {(attrs) => (
                      <span
                        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                        tabIndex={0}
                        {...attrs}
                        aria-labelledby="genius_airlines_tooltip"
                        className={styles.tooltip__icon__wrapper}
                      >
                        <Icon attributes={{ "aria-hidden": true }} svg={<InfoSignIcon />} size="smallest" />
                      </span>
                    )}
                  </Popover.Trigger>
                  <Popover.Content
                    attributes={{
                      "aria-label": geniusAirlineBannerData.tooltip,
                      id: "genius_airlines_tooltip",
                      role: "tooltip"
                    }}
                  >
                    <Text variant="body_1">{geniusAirlineBannerData.tooltip}</Text>
                  </Popover.Content>
                </Popover>
              )}
            </Stack>
          }
          dismissible={false}
        >
          <Frame mt={1}>{carriersList}</Frame>
        </Banner>
      </Frame>
    </Container>
  );
});
