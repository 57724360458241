import { t } from "@bookingcom/lingojs-core";
import { I18nChildContext } from "@bookingcom/lingojs-react";
import { COMPANY_NAME } from "@flights/constants";

interface FaqItem {
  question: string;
  answer: string;
}

function getFaqData(i18n: I18nChildContext): FaqItem[] {
  return [
    /**
     * How do I find the cheapest flights on {b_companyname}?
     */
    {
      question: i18n.trans(
        t("seo_flight_index_lp_faq", "1", "question", {
          variables: {
            b_companyname: COMPANY_NAME
          }
        })
      ),
      answer: i18n.trans(t("seo_flight_index_lp_faq", "1", "answer_1"))
    },

    /**
     * Can I book one way flight tickets on {b_companyname}?
     */
    {
      question: i18n.trans(
        t("seo_flight_index_lp_faq", "2", "question", {
          variables: {
            b_companyname: COMPANY_NAME
          }
        })
      ),
      answer: i18n.trans(t("seo_flight_index_lp_faq", "2", "answer_1"))
    },

    /**
     * How far in advance can I book a flight?
     */
    {
      question: i18n.trans(t("seo_flight_index_lp_faq", "3", "question")),
      answer: i18n.trans(t("seo_flight_index_lp_faq", "3", "answer_1"))
    },

    /**
     * Do flights get cheaper closer to departure?
     */
    {
      question: i18n.trans(t("seo_flight_index_lp_faq", "4", "question")),
      answer: i18n.trans(t("seo_flight_index_lp_faq", "4", "answer_1"))
    },

    /**
     * What is a flexible ticket?
     */
    {
      question: i18n.trans(t("seo_flight_index_lp_faq", "5", "question")),
      answer: i18n.trans(t("seo_flight_index_lp_faq", "5", "answer_1"))
    },

    /**
     * Does {b_companyname} charge credit card fees?
     */
    {
      question: i18n.trans(
        t("seo_flight_index_lp_faq", "6", "question", {
          variables: {
            b_companyname: COMPANY_NAME
          }
        })
      ),
      answer: i18n.trans(t("seo_flight_index_lp_faq", "6", "answer_1"))
    }
  ];
}

export { FaqItem, getFaqData };
