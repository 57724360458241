import React, { useMemo } from "react";
import { format } from "date-fns";

import { t } from "@bookingcom/lingojs-core";
import { useI18n } from "@bookingcom/lingojs-react";

import { OpenSkyFlightResult } from "@flights/types";
import { formattingUtils } from "@flights/web-api-utils-universal";

import useFormatPrice from "hooks/useFormatPrice";
import { useGenerateWeekendBreakCardUrl } from "components/elements/WeekendBreaks/utils";
import WeekendBreaksItemInnerDesktop from "./WeekendBreaksItemInner.desktop";
import WeekendBreaksItemInnerMobile from "./WeekendBreaksItemInner.mobile";
import useUserAgent from "hooks/useUserAgent";

const WeekendBreaksItem = ({ item }: { item: OpenSkyFlightResult }) => {
  const i18n = useI18n();
  const { formatPrice } = useFormatPrice();
  const { isMobile } = useUserAgent();

  const {
    arrivalCityIata,
    arrivalCityNameTranslated,
    departureCityIata,
    departureCountry,
    currencyCode,
    departureTime,
    outboundDuration,
    priceUnits,
    returnDepartureTime,
    salesCountry,
    tripType,
    numberOfAdults,
    cabinClass,
    arrivalCityImageUrl,
    departureDayOfWeek,
    tripDurationDays,
    returnDuration
  } = item;

  const maxDuration = Math.max(outboundDuration, returnDuration);
  const duration = formattingUtils.convertMinutesToHours(maxDuration);
  const addSpaceIfHasHours = duration.hours > 0 ? " " : "";

  const durationCopy = useMemo(() => {
    return `${
      duration.hours > 0
        ? i18n.trans(
            t("flights_cheap_weekend_carousel_duration_h", {
              variables: { flight_duration_h: duration.hours },
              num_exception: duration.hours
            })
          )
        : ""
    }${
      duration.minutes > 0
        ? `${addSpaceIfHasHours}${i18n.trans(
            t("flights_cheap_weekend_carousel_duration_m", {
              variables: { flight_duration_m: duration.minutes },
              num_exception: duration.minutes
            })
          )}`
        : ""
    }`;
  }, [addSpaceIfHasHours, duration.hours, duration.minutes, i18n]);

  const price = formatPrice({ currencyCode, nanos: 0, units: priceUnits }, { decimalPlaces: 0 });

  const linkUrlSR = useGenerateWeekendBreakCardUrl(
    arrivalCityIata,
    departureCityIata,
    salesCountry,
    departureCountry,
    format(new Date(departureTime), "yyyy-MM-dd"),
    format(new Date(returnDepartureTime), "yyyy-MM-dd"),
    tripType,
    numberOfAdults.toString(),
    cabinClass
  );

  return isMobile ? (
    <WeekendBreaksItemInnerMobile
      price={price}
      linkUrlSR={linkUrlSR}
      arrivalCityImageUrl={arrivalCityImageUrl}
      arrivalCityNameTranslated={arrivalCityNameTranslated}
      departureTime={departureTime}
      returnDepartureTime={returnDepartureTime}
      departureDayOfWeek={departureDayOfWeek}
      tripDurationDays={tripDurationDays}
    />
  ) : (
    <WeekendBreaksItemInnerDesktop
      price={price}
      linkUrlSR={linkUrlSR}
      durationCopy={durationCopy}
      arrivalCityImageUrl={arrivalCityImageUrl}
      arrivalCityNameTranslated={arrivalCityNameTranslated}
      departureTime={departureTime}
      returnDepartureTime={returnDepartureTime}
      departureDayOfWeek={departureDayOfWeek}
      tripDurationDays={tripDurationDays}
    />
  );
};

export default WeekendBreaksItem;
